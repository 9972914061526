.History {
  z-index: 3;
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 4px rgba(0, 0, 0, 0.1);
}

.History__header {
  padding: 20px 20px 0 0;
  text-align: right;
}

.History__details {
  padding: 0 20px;
}

.History__header svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.History__chart {
  position: relative;
  margin: 20px;
  height: 100%;
}

.History_wrap {
  position: relative;
  height: 80%;
}

@media (min-width: 1000px) {
  .History {
    top: 50px;
    right: 50px;
    left: 50px;
    bottom: 50px;
  }
}
