.ApartmentsHeatmap__head {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.ApartmentsHeatmap .select-date select {
  font-size: 20px;
}

.ApartmentsHeatmap__disclaimer {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  margin-left: 10px;
}

.ApartmentsHeatmap__disclaimer svg {
  width: 30px;
  height: 30px;
}
