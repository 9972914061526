.Disclaimer {
  z-index: 3;
  position: absolute;
  top: 50px;
  right: 20px;
  left: 20px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 4px rgba(0, 0, 0, 0.1);

}

.Disclaimer__header {
  padding: 20px 20px 0 0;
  text-align: right;
}

.Disclaimer__details {
  padding: 0 20px 20px 20px;
}

.Disclaimer__header svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

